<template>
  <div id='doctorList'>
    <!-- 标题 -->
    <div class="labelBox">
      <div class="title">宣教文章分类</div>
      <div>
        <el-button type="primary" size="medium" @click="add">添加分类</el-button>
      </div>
    </div>

    <!-- 类型列表 -->
    <el-table :data="list" style="width: 100%">
      <el-table-column prop="id" label="ID" width="50">
      </el-table-column>
      <el-table-column prop="typeName" label="类型名称" width="400">
      </el-table-column>
      <el-table-column prop="order" label="排序">
      </el-table-column>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag type="success" size="small" v-if="scope.row.open === 1">展示</el-tag>
          <el-tag type="danger" size="small" v-else>隐藏</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="添加时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="edit(scope.row.id)">编辑</el-button>
          <el-divider direction="vertical"></el-divider>
          <el-popconfirm title="确定删除吗？" @confirm="del(scope.row.id)">
            <el-button type="text" slot="reference" style="color:#F56C6C">删除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加问卷类型弹窗 -->
    <el-dialog :title="editTitle" :visible.sync="editModal" width="50%">
      <div class="modalBox">
        <el-form :model="editForm" :rules="rules" ref="editForm" label-width="150px" class="demo-ruleForm">
          <el-form-item label="类型名称" prop="typeName">
            <el-input v-model="editForm.typeName"></el-input>
          </el-form-item>
          <el-form-item label="排序" prop="order">
            <el-input-number :min="0" v-model="editForm.order"></el-input-number>
						<p>数字大的排在前面 例如 99 > 50</p>
          </el-form-item>
          <el-form-item label="是否开启" prop="open">
            <el-radio v-model="editForm.open" :label="1">开启</el-radio>
            <el-radio v-model="editForm.open" :label="0">关闭</el-radio>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editModal = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'setting',
    data() {
      return {
        id: '',
        list: [],
        changeData: [],
        miniList: [],
        editModal: false,
        editTitle: '',
        editForm: {
          typeName: '',
          order: 0,
          open: 1
        },
        rules: {
          typeName: [{
            required: true,
            message: '请填写问卷类型名称',
            trigger: 'blur'
          }],
          order: [{
            required: true,
            message: '请填写排序',
            trigger: 'blur'
          }],
          open: [{
            required: true,
            message: '请选择是否开启',
            trigger: 'change'
          }],
        }
      }
    },

    created() {
      this.getList()
    },

    methods: {
      add() {
        this.editForm = {}
        this.id = ''
        this.editTitle = '添加问卷类型'
        this.editModal = true
      },

      edit(id) {
        this.id = id
        this.editTitle = '编辑问卷类型'
        this.$http.post('/study/typeView', {
          id: this.id
        }).then(res => {
          if (res.data.code == 1) {
            this.editForm = res.data.data
            this.editModal = true
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      submitForm() {
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            var data = {
              typeName: this.editForm.typeName,
              order: this.editForm.order,
              open: this.editForm.open
            }
            if (this.id) {
              data.id = this.id
            }
            this.$http.post('/study/typeEdit', data).then(res => {
              if (res.data.code == 1) {
                this.editModal = false
                this.getList()
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          }
        });
      },

      del(id) {
        this.$http.post('/study/typeDel', {
          id: id
        }).then(res => {
          if (res.data.code == 1) {
            this.getList()
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },

      getList() {
        this.$http.post('/study/typeList', {}).then(res => {
          if (res.data.code == 1) {
            this.list = res.data.data
          }
        })
      },
			
    }
  }
</script>

<style scoped>
  #doctorList {
    padding: 30px;
    background: #fff;
  }

  .modalBox {
    padding-right: 100px;
    /* overflow:scroll; */
    overflow-y: auto
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: auto;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: auto;
    display: block;
  }

  .paginationBox {
    margin: 20px 0;
    text-align: right;
  }
</style>